'use client';

import React, { PropsWithChildren, Suspense } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { GlobalSettingsPageData } from '@/lib/apis/butter-api';
import { Navbar } from '~/components/navbar';
import { useConfiguration } from '~/utils/config';
import { Footer } from '~/components/footer';
import { MaintenanceDialog } from '~/components/dialogs';
import { fontAwesomeClientService } from '@/lib/font-awesome-icons/font-awesome.client-service';
import { cookiesService } from '@/lib/services/cookies/cookies.service';
import { COOKIE_KEYS } from '@/lib/services/cookies/constants';

export const PublicLayout = (
  props: PropsWithChildren<{
    globalSettings: GlobalSettingsPageData;
    globalSettingsIcons: IconDefinition[];
  }>,
) => {
  fontAwesomeClientService.includeIcons(props.globalSettingsIcons);

  const { maintenanceMode } = useConfiguration();

  const [isPopupOpen, handlePopup] = React.useState<boolean>(
    maintenanceMode &&
      !(
        cookiesService.getCookie(
          COOKIE_KEYS.MY_GRIMME_CORE_MAINTENANCE_POPUP,
        ) === 'true'
      ),
  );

  const socialLinks = props.globalSettings.social_links;
  const topLinks = props.globalSettings.top_links;
  const navigationLinks = props.globalSettings.navigation_menu;
  const navbarData = props.globalSettings.navbar;

  return (
    <>
      <Suspense>
        <Navbar
          greeting={props.globalSettings.greeting}
          loggedInMenu={props.globalSettings.logged_in_menu}
          loggedOutMenu={props.globalSettings.logged_out_menu}
          more={props.globalSettings.more}
          navbarData={navbarData}
          navigationLinks={navigationLinks}
          socialLinks={socialLinks}
          topLinks={topLinks}
          withoutContactAssistant={false}
        />
      </Suspense>
      <main>{props.children}</main>
      {/*
        TODO: Item keys need to be updated in the Old Library Footer
        because is giving an key prop error not fixable in the project.
      */}
      <Footer
        footerData={props.globalSettings.footer}
        socialLinks={socialLinks}
        topLinks={topLinks}
        navigationLinks={props.globalSettings.navigation_menu}
      />
      <MaintenanceDialog
        onConfirm={() => {
          cookiesService.setCookie(
            COOKIE_KEYS.MY_GRIMME_CORE_MAINTENANCE_POPUP,
            'true',
          );
          handlePopup(!isPopupOpen);
        }}
        open={isPopupOpen}
      />
    </>
  );
};
