import { COOKIE_KEYS } from './constants';

class CookiesService {
  private defaultTTLInSeconds = 2592000; // Default is set to 30 days

  setCookie = async (
    name: COOKIE_KEYS,
    value: string,
    expiryInSeconds?: number,
  ) => {
    if (typeof window === 'undefined') {
      return;
    }

    const currentDate = new Date();
    const expiryUnitInSeconds = expiryInSeconds || this.defaultTTLInSeconds;
    currentDate.setTime(currentDate.getTime() + expiryUnitInSeconds * 1000);

    const cookieString = `${name}=${encodeURIComponent(
      value,
    )}; expires=${currentDate.toString()}; path=/`;

    document.cookie = cookieString;

    let cookieValue = this.getCookie(name);

    // return only when cookie is set
    while (cookieValue !== value) {
      await new Promise((resolve) => setTimeout(() => resolve(true), 100));

      cookieValue = this.getCookie(name);
    }
  };

  setTLDCookie = async (
    name: COOKIE_KEYS,
    value: string,
    expiryInSeconds?: number,
  ) => {
    if (typeof window === 'undefined') {
      return;
    }

    const currentDate = new Date();
    const expiryUnitInSeconds = expiryInSeconds || this.defaultTTLInSeconds;
    currentDate.setTime(currentDate.getTime() + expiryUnitInSeconds * 1000);

    const cookieString = `${name}=${encodeURIComponent(
      value,
    )}; expires=${currentDate.toString()}; path=/`;

    /**
     * We have a cookie getting duplicated cos of subdomain/tld setting.
     * When reading cookie we don't have access to domain data
     * That means we can't pick which cookie to read
     * To avoid further bugs we need to override all cookies having same name, no matter what domain they've been assigned to
     * Carpet bombing override is the only option to make sure all cookie values are in sync across all possible domains
     *
     * Ideally, client side calls should set cookie only to its own domains.
     * TLD cookies should be resolved by server side.
     */

    const tld = window.location.hostname.split('.').slice(-2).join('.');
    document.cookie = `${cookieString}; domain=.${tld}; SameSite=lax`;

    let cookieValue = this.getCookie(name);

    // return only when cookie is set
    while (cookieValue !== value) {
      await new Promise((resolve) => setTimeout(() => resolve(true), 100));

      cookieValue = this.getCookie(name);
    }
  };

  getCookie = (name: COOKIE_KEYS) => {
    if (typeof window === 'undefined') {
      return;
    }

    const targetCookiesValues = `${document?.cookie}`
      .split(`;`)
      .filter((cookie) => cookie.includes(`${name}=`))
      .map((cookie) => cookie.split('=')[1]);

    return targetCookiesValues[0];
  };
}

export const cookiesService = new CookiesService();
