import { IconLookup } from '@fortawesome/fontawesome-svg-core';
import { GlobalSettingsPageData } from '@/lib/apis/butter-api';

export const getFooterData = (data: GlobalSettingsPageData['footer']) => {
  return {
    company_information: [
      {
        city: data?.city,
        company_name: data?.company_name,
        email: data?.email,
        phone: data?.phone,
        postal_code: data?.postal_code,
        street_address: data?.street_address,
        website: data?.website,
      },
    ],
    legal_information_links: data?.legal_information_links,
    title: data?.title,
  };
};

export const getNavigationLinks = (
  navigationLinks: GlobalSettingsPageData['navigation_menu'],
) => {
  return navigationLinks
    ?.map((l) => l.fields)
    ?.map((link) => ({
      label: link.label,
      url: link.url,
    }));
};

export const getSocialLinks = (
  links: GlobalSettingsPageData['social_links'],
) => {
  return links?.links?.map((socialLink) => ({
    icon: {
      iconName: socialLink.icon,
      prefix: socialLink.icon_type,
    } as IconLookup,
    url: socialLink.url,
  }));
};

export const getSitemap = (
  data: GlobalSettingsPageData['footer'],
  navigation: ReturnType<typeof getNavigationLinks>,
  links: GlobalSettingsPageData['top_links'],
) => {
  return [
    {
      caption: data?.sitemap_title,
      links: navigation,
    },
    {
      caption: links?.title,
      links: links?.links,
    },
  ];
};
