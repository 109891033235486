import { Footer as GrimmeFooter } from '@grimme/components';
import { GlobalSettingsPageData } from '@/lib/apis/butter-api';
import {
  getFooterData,
  getNavigationLinks,
  getSitemap,
  getSocialLinks,
} from './utils';

export type FooterProps = {
  footerData: GlobalSettingsPageData['footer'];
  navigationLinks: GlobalSettingsPageData['navigation_menu'];
  socialLinks: GlobalSettingsPageData['social_links'];
  topLinks: GlobalSettingsPageData['top_links'];
};

export const Footer = (props: FooterProps) => {
  const { footerData, navigationLinks, socialLinks, topLinks } = props;
  const navigation = getNavigationLinks(navigationLinks);

  return (
    <GrimmeFooter
      data={getFooterData(footerData)}
      socialLinks={getSocialLinks(socialLinks)}
      sitemap={getSitemap(footerData, navigation, topLinks)}
    />
  );
};
