import { faInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog } from '@grimme/components';
import { Typography } from '@mui/material';
import { useTranslations } from '@/lib/i18n/use-translations';

interface IMaintenanceDialogProps {
  onConfirm?: () => void;
  open: boolean;
}

export const MaintenanceDialog = (props: IMaintenanceDialogProps) => {
  const { onConfirm, open } = props;
  const t = useTranslations();

  const confirmLabel = t(
    'myGRIMME_core_under_maintenance_info_confirm',
    'Verstanden',
  );
  const title = t(
    'myGRIMME_core_under_maintenance_info_headline',
    'Information',
  );
  const paragraph = t(
    'myGRIMME_core_under_maintenance_info_text',
    'Aufgrund dringender Wartungsarbeiten kann es derzeit zu einer eingeschränkten Verfügbarkeit einiger Funktionen in myGRIMME kommen.',
  );

  return (
    <Dialog
      labelConfirm={confirmLabel}
      icon={<FontAwesomeIcon icon={faInfo} />}
      onConfirm={onConfirm}
      open={open}
      title={title}
    >
      <Typography component="div" variant="body1">
        {paragraph}
      </Typography>
    </Dialog>
  );
};
