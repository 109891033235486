import {
  ContactAssistant,
  Navbar as GrimmeNavbar,
  UserSettingsContent,
  UserSettingsMenu,
} from '@grimme/components';
import { signIn, useSession } from '@grimme/next-grimme-auth';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useSearchParams } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';
import { useLocale } from 'next-intl';
import { WhatsappDialog } from '~/components/whatsapp-dialog';
import { Root } from '~/components/navbar/styles';
import { UserGreeting } from '~/components/navbar/user-greeting';
import { UserMenu } from '~/components/navbar/user-menu';
import {
  mapAssistantLinks,
  mapContactLinks,
  mapNavigationLinks,
  mapSocialMediaLinks,
  reformatAssistantData,
  reformatWhatsappNumber,
} from '~/components/navbar/utils';
import { environment } from '~/environments';
import { useContactDetails, useGeolocation } from '~/hooks';
import { useAppDispatch, useAppSelector } from '~/redux';
import { getUser, updateProfileGrid } from '~/redux/auth.slice';
import { register } from '~/utils/auth/register';
import { signOut } from '~/utils/auth/sign-out';
import { DEFAULT_MOBILE_PHONE } from '~/utils/consts';
import languages from '~/utils/data/languages.json';
import { GlobalSettingsPageData } from '@/lib/apis/butter-api';
import { useTranslations } from '@/lib/i18n/use-translations';
import { usePathname, useRouter } from '@/lib/i18n/routing';
import { cookiesService } from '@/lib/services/cookies/cookies.service';
import { COOKIE_KEYS } from '@/lib/services/cookies/constants';

export type TNavbar = {
  greeting: GlobalSettingsPageData['greeting'];
  loggedInMenu: GlobalSettingsPageData['logged_in_menu'];
  loggedOutMenu: GlobalSettingsPageData['logged_out_menu'];
  more: GlobalSettingsPageData['more'];
  navbarData: GlobalSettingsPageData['navbar'];
  navigationLinks: GlobalSettingsPageData['navigation_menu'];
  socialLinks: GlobalSettingsPageData['social_links'];
  topLinks: GlobalSettingsPageData['top_links'];
  withoutContactAssistant: boolean;
};

export const Navbar = (props: TNavbar) => {
  const {
    greeting,
    loggedInMenu,
    loggedOutMenu,
    more,
    navbarData,
    navigationLinks,
    socialLinks,
    topLinks,
  } = props;
  const { data: contactAssistantData } = useContactDetails();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const router = useRouter();

  const insights = useAppInsightsContext();
  const { status } = useSession();
  const userData = useAppSelector(getUser);

  const isUserLoggedIn = status === 'authenticated';
  const isLoading = status === 'loading' || (isUserLoggedIn && !userData);
  const geolocationData = useGeolocation();
  const userIconRef = useRef<HTMLButtonElement>(null);
  const t = useTranslations();
  const locale = useLocale();
  const defaultWhatsAppState = Boolean(
    typeof localStorage !== 'undefined' &&
      localStorage.getItem('allowWhatsapp'),
  );
  const contactAssistantStateCookie = cookiesService.getCookie(
    COOKIE_KEYS.CONTACT_ASSISTANT_COOKIE,
  );
  const shouldShowContactAssistantOnMount = Boolean(
    contactAssistantStateCookie === 'enabled' || !contactAssistantStateCookie,
  );
  const whatsappText = t('myGRIMME_website_whatsapp_cta', 'Zum Chat');

  const [whatsAppConsent, setWhatsAppConsent] = useState(defaultWhatsAppState);
  const [showConsentDialog, setShowConsentDialog] = useState<boolean>(false);
  const [isShowingContactAssistantState, setIsShowingContactAssistantState] =
    useState<boolean>(shouldShowContactAssistantOnMount);
  const isShowingContactAssistant =
    isShowingContactAssistantState && !!contactAssistantData;
  const [isShowingUserSettings, setIsShowingUserSettings] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();

  const assistantAvatar =
    contactAssistantData?.photo &&
    `data:image/jpeg;base64,${contactAssistantData?.photo}`;
  const assistantLabel = t('myGRIMME_products_contact_assistant', 'Beratung');
  const assistantName = [
    contactAssistantData?.firstName,
    contactAssistantData?.lastName,
  ].join(' ');
  const contactNumber =
    contactAssistantData?.phone || contactAssistantData?.mobilePhone;
  const contactAssistantEmail = contactAssistantData?.email || ' ';
  const selectWhatsappNumber =
    contactAssistantData?.mobilePhone || DEFAULT_MOBILE_PHONE;
  const whatsappNumber = reformatWhatsappNumber(selectWhatsappNumber);

  const toggleContactAssistantVisibility = () => {
    setIsShowingContactAssistantState((prevIsShowingContactAssistant) => {
      cookiesService.setTLDCookie(
        COOKIE_KEYS.CONTACT_ASSISTANT_COOKIE,
        prevIsShowingContactAssistant ? 'disabled' : 'enabled',
      );

      return !prevIsShowingContactAssistant;
    });
  };

  const onEmailClick = () => {
    window.open(`mailto: ${contactAssistantData?.email}`, '_self');
    insights?.trackEvent({
      name: 'Contacted Rep',
      properties: {
        method: 'email',
        rep: contactAssistantData?.email,
      },
    });
  };

  const { data: sessionData } = useSession();

  useEffect(() => {
    if (
      status === 'authenticated' &&
      userData?.Language?.toLowerCase() !==
        (locale as string | undefined)?.toLowerCase()
    ) {
      dispatch(
        updateProfileGrid({
          accessToken: sessionData?.accessToken ?? '',
          payload: {
            preferredLanguageCode: (
              locale as string | undefined
            )?.toLowerCase(),
          },
          suppressNotifications: true,
        }),
      );
    }
  }, [status, userData?.Language]);

  const onLanguageChange = async (lng: string) => {
    // Removing locale prefix (i.e. /de, /en, etc) from current route's pathname
    // so we stay on the same route
    // and next-intl resolves next locale for current route gracefully (i.e. sets cookies according to configs)
    const routePathName = pathname.replace(`/${locale}`, '') || '/';

    // Mapping searchParams structure to { key: value } format
    // so any queries are preserved during language switch
    const query = Object.fromEntries(searchParams.entries());

    router.push({ pathname: routePathName, query }, { locale: lng });
  };

  const onPhoneClick = () => {
    window.open(`tel:${contactNumber}`);
    insights?.trackEvent({
      name: 'Contacted Rep',
      properties: {
        method: 'phone',
        rep: contactNumber,
      },
    });
  };

  const onWhatsAppClick = () => {
    if (!whatsAppConsent) {
      setShowConsentDialog(true);
    } else {
      window.open(`https://wa.me/${whatsappNumber}`);
      insights?.trackEvent({
        name: 'Contacted Rep',
        properties: {
          method: 'whatsapp',
          rep: whatsappNumber,
        },
      });
    }
  };

  const phoneData = { contactNumber: contactNumber as string, onPhoneClick };
  const emailData = {
    contactAssistantEmail: contactAssistantEmail,
    onEmailClick,
  };
  const whatsappData = {
    onWhatsAppClick,
    whatsappNumber: whatsappNumber as string,
    whatsappText: whatsappText as string,
  };
  const assistantData = reformatAssistantData(
    phoneData,
    emailData,
    whatsappData,
  );
  const assistantLinks = mapAssistantLinks(assistantData);
  const mappedContactLinks = mapContactLinks(assistantData);
  const mappedNavigationLinks = mapNavigationLinks(navigationLinks);
  const mappedSocialLinks = mapSocialMediaLinks(socialLinks);

  const handleOnNavbarLogoClick = () => {
    router.push(environment.grimmeWebsiteUrl);
  };

  const handleOnUserMenuLogoClick = () => {
    router.push('/');
  };

  const handleOnLogout = () => {
    signOut();
  };

  return (
    <Root>
      <GrimmeNavbar
        additionalLinks={navbarData?.additional_links}
        isAuthenticated={isUserLoggedIn}
        links={mappedNavigationLinks}
        onLogoClick={handleOnNavbarLogoClick}
        socialLinks={mappedSocialLinks}
        topLinks={topLinks?.links}
        topLinksLabel={topLinks?.title}
      >
        <UserGreeting
          greeting={greeting}
          more={more}
          ref={userIconRef}
          setIsShowingUserSettings={setIsShowingUserSettings}
        />
        <UserSettingsMenu
          anchorEl={userIconRef.current}
          onBackdropClick={() => setIsShowingUserSettings(false)}
          open={isShowingUserSettings}
        >
          <UserSettingsContent
            MenuComponent={
              <UserMenu
                loggedInMenu={loggedInMenu}
                loggedOutMenu={loggedOutMenu}
                more={more}
              />
            }
            contactLinks={mappedContactLinks}
            contactName={assistantName}
            geolocation={geolocationData}
            isLoading={isLoading}
            isSessionValid={isUserLoggedIn}
            isShowingContact={isShowingContactAssistant}
            onClose={() => setIsShowingUserSettings(false)}
            onHideContact={toggleContactAssistantVisibility}
            onLanguageChange={onLanguageChange}
            onLogin={() => signIn()}
            onLogoClick={handleOnUserMenuLogoClick}
            onLogout={handleOnLogout}
            onRegister={() => register(locale)}
            supportedLanguages={languages}
          />
        </UserSettingsMenu>
      </GrimmeNavbar>

      <ContactAssistant
        details={{
          avatar: assistantAvatar,
          description: assistantLabel,
          name: assistantName,
        }}
        indented
        links={assistantLinks}
        onVisibilityChange={toggleContactAssistantVisibility}
        visible={isShowingContactAssistant}
      />
      <WhatsappDialog
        contactNumber={whatsappNumber}
        open={showConsentDialog}
        onCancel={() => {
          setShowConsentDialog(false);
        }}
        onConfirm={() => {
          setWhatsAppConsent(true);
          setShowConsentDialog(false);
        }}
      />
    </Root>
  );
};
