import { LoadingMenu, LoggedOutMenu } from '@grimme/components';
import { useFormattedProfileData } from '~/hooks';
import { GlobalSettingsPageData } from '@/lib/apis/butter-api';
import {
  createImagePlaceHolder,
  mapLoggedInMenuItems,
  mapLoggedOutMenuItems,
} from '../utils';
import { LoggedInMenu } from './logged-in-menu';

type UserMenuProps = {
  loggedInMenu?: GlobalSettingsPageData['logged_in_menu'];
  loggedOutMenu?: GlobalSettingsPageData['logged_out_menu'];
  more: GlobalSettingsPageData['more'];
};

export const UserMenu = (props: UserMenuProps) => {
  const { loggedInMenu, loggedOutMenu, more } = props;

  const { company, fullName, initials, isLoading, isUserDataLoaded } =
    useFormattedProfileData(more);
  const mappedLoggedOutMenuItems =
    loggedOutMenu && mapLoggedOutMenuItems(loggedOutMenu);
  const mappedLoggedInMenuItems =
    loggedInMenu && mapLoggedInMenuItems(loggedInMenu);
  const UserImagePlaceHolder = initials && createImagePlaceHolder(initials);

  const Menu = () => {
    if (isLoading) return <LoadingMenu />;

    if (isUserDataLoaded && fullName) {
      return (
        <LoggedInMenu
          company={company}
          image={UserImagePlaceHolder}
          menuItems={mappedLoggedInMenuItems}
          name={fullName}
        />
      );
    }

    if (!isUserDataLoaded && loggedOutMenu) {
      return (
        <LoggedOutMenu
          menuItems={mappedLoggedOutMenuItems}
          subtitle={loggedOutMenu.subtitle}
          title={loggedOutMenu.title}
        />
      );
    }

    return null;
  };

  return <Menu />;
};
